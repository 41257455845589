import React from "react";
import moment from "moment";
import {splitFromCapital} from "../../utils/utility/commenFunctions"
import { useTranslation } from "react-i18next";
import { getCabinName } from "../../utils/utility/getCabinName";
import { OverlayTrigger, Popover } from "react-bootstrap";

function timeConvert(n) {
  let number = n;
  let hours = (number / 60);
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
  return number = rhours + "h " + rminutes + "m";
}

function FlightDetailsTabMultiCity(props) {

  let fareBasisDetails = props.paxFare &&
  props.paxFare[0] &&
  props.paxFare[0].fareBasisDetails
  
  var cabin;
  let flightTime = 0;
  const itinenaryData = props.data
  const { t } = useTranslation();
  return (
    <>
      {itinenaryData && itinenaryData.map(data => {
        
        return (
          <>
            {Object.values(data).map(value => {

              cabin = value && value.cabin
              let cabinName = getCabinName(value.flights);
              let cabinInfoForBookingPage = value && value?.classOfService;
              return (
                <>
                  <div>
                    {value && value.flights && value.flights.map((flight, index) => {

                      let num = timeConvert(flight.durationMin);
                      let integerValue = parseInt(flight.durationMin);
                      flightTime += integerValue
                      let depdateTime = moment(flight.departureDateTime).format("HH:mm");
                      let arrdateTime = moment(flight.arrivalDateTime).format("HH:mm");
                      let cabinVal;
                      let brandedFareDetails = fareBasisDetails && fareBasisDetails.filter(flightId => flightId.flightId === flight.id)
                      let classOfService = brandedFareDetails && brandedFareDetails[0] && brandedFareDetails[0].classOfService
                      let brandedFareCode = brandedFareDetails &&
                      brandedFareDetails[0] &&
                      brandedFareDetails[0].brand &&
                      brandedFareDetails[0].brand.code
                      let brandedFareName = brandedFareDetails &&
                      brandedFareDetails[0] &&
                      brandedFareDetails[0].brand &&
                      brandedFareDetails[0].brand.brandName
                      
                      if(flight.cabin === undefined){
                        let cabinInfo = cabinInfoForBookingPage && cabinInfoForBookingPage.filter(flightId => flightId.flightId === flight.id)
                        if(cabinInfo && cabinInfo.length > 0){
                          cabinVal = cabinInfo && cabinInfo[0] && cabinInfo[0].cabin;
                          cabinName = getCabinName(cabinInfoForBookingPage);
                          
                        }
                      }
                      let newFlightDetail=fareBasisDetails && fareBasisDetails.filter(flightId => flightId.flightId === flight.id);
                      let newCabinVal = newFlightDetail && newFlightDetail[0] && newFlightDetail[0].cabin;

                      let aircraftDescription = flight && flight.aircraftDescription;
                      let aircraftType = flight && flight.aircraftType;
                      let aircraftToDisplay = aircraftDescription !== undefined ? aircraftDescription : aircraftType;
                      let operatingCarrierName = flight && flight.operatingCarrierName;
                      let operatingCarrierNameToDisplay = operatingCarrierName !== undefined ? operatingCarrierName : "";
                      // STOP OVER STARTED
                      let stopOverDetails = flight && flight.technicalStops && flight.technicalStops[0]                   
                      let stopOverAirport = stopOverDetails && stopOverDetails.airport;
                      let stopOverDuration = stopOverDetails && stopOverDetails.durationMin;
                      // STOP OVER END
                      return (
                        <>
                          <div className="flight_tabs_hader">
                            <div class="row head">
                              <div className="flight_tabs-left">
                                <div className="text_title">
                                  <span>
                                    {flight.carrierName !== undefined ? flight.carrierName : flight.carrierName}{" "}
                                    {flight.carrier} {flight.number} {classOfService}
                                  </span>
                                  {newCabinVal !== undefined ?
                                  <>
                                  {newCabinVal &&
                                  <span className={cabinName === "Mixed" ? "flightDetails-cabinType" : ""}>
                                    ({newCabinVal && t(splitFromCapital(newCabinVal))})
                                  </span>  
                                  }
                                  </>
                                  :
                                  <span className={cabinName === "Mixed" ? "flightDetails-cabinType" : ""}>
                                    ({flight.cabin && t(splitFromCapital(flight.cabin))})
                                  </span>                                  
                                  }
                                </div>
                              </div>
                              <div className="flight_tabs-right">
                                {brandedFareCode !== undefined ?
                                <OverlayTrigger
                                  trigger="hover"
                                  placement="top"
                                  delay={{ show: 100 }}
                                  overlay={
                                    <Popover className="seatView-popover">
                                    <div className="seatView-popover-row seatView-popover-amount">
                                    {t("Brand Code :")} {brandedFareCode}
                                      </div>
                                    </Popover>
                                  }
                                >
                                  {brandedFareName !== undefined && <div className="fareOption">{t("Fare Option")} {brandedFareName} </div>}
                                </OverlayTrigger> : brandedFareName !== undefined && <div className="fareOption">{t("Fare Option")} {brandedFareName}</div>}


                              </div>
                            </div>
                            <div className="row flight_tabs-side">

                              <span>
                                {" "}
                                {flight.operatingCarrier && t("Operated by:") + operatingCarrierNameToDisplay + " (" + flight.operatingCarrier + ")"}
                                {aircraftToDisplay ? " | " + aircraftToDisplay : ""}
                                {flight && flight.aircraftNoOfSeats &&
                                  <span>{" | " + flight.aircraftNoOfSeats + t("Seats")}</span>
                                }
                                {flight && flight.airlineRecordLocator &&
                                  <span>{" | " + t("AIRLINE REFERENCE") + flight.airlineRecordLocator}</span>
                                }
                              </span>
                            </div>
                          </div>




                          <div className="f-details-senction">
                            <div className="f-details-col">
                              <div className="fd-name-time">{flight && flight.departureAirportCity} ({flight.departureAirport})<br /><span>{depdateTime}</span></div>
                              {flight.departureTerminal !== undefined ?
                                <span className="fd-aireport-detail">{t("Terminal") + flight.departureTerminal}</span>
                                :
                                <span className="fd-aireport-detail">{t("Terminal") + " -"}</span>
                              }
                            </div>
                            <div className="fd-time">
                              {num} <i className="far fa-clock"></i>
                            </div>
                            <div className="f-details-col">
                              <div className="fd-name-time">{flight && flight.arrivalAirportCity} ({flight.arrivalAirport})<br /><span>{arrdateTime}</span></div>
                              {flight.arrivalTerminal !== undefined ?
                                <span className="fd-aireport-detail">{t("Terminal") + flight.arrivalTerminal}</span>
                                :
                                <span className="fd-aireport-detail">{t("Terminal") + " -"}</span>
                              }
                            </div>
                          </div>


                          {value && value.layovers && value.layovers[index]
                            ?
                            <>
                            {stopOverAirport ?
                            <div className='f-DetailsLayover'>
                              <div className='f-DetailsLayoverTitle'>
                                  <span className='f-DetailsLayoverTym'>
                                    {timeConvert(stopOverDuration)}{" "} 
                                    <span className="bd-duration-text">Technical Stopover in {stopOverAirport}</span> 
                                    <div> + </div>
                                    <div>
                                      {timeConvert(value.layovers[index].durationMinutes)} 
                                      <span className="bd-duration-text"> {t("Layover in") + flight.arrivalAirport} </span>
                                    </div>
                                  </span>                                  
                              </div>
                            </div>
                            :
                            <div className='f-DetailsLayover'>
                              <div className='f-DetailsLayoverTitle'>
                                <span className='f-DetailsLayoverTym'>{timeConvert(value.layovers[index].durationMinutes)} </span>
                                {t("Layover in") + flight.arrivalAirport}
                              </div>
                            </div>
                            }
                            </>
                            : 
                            <>
                            {stopOverAirport ?
                            <div className="f-DetailsLayover">
                              <div className="f-DetailsLayoverTitle">
                                <span className="f-DetailsLayoverTym">
                                {timeConvert(stopOverDuration)}{" "}                
                                </span>
                                Technical Stopover in {stopOverAirport}
                              </div>
                            </div> 
                            : 
                            null            
                            }             
                            </>
                          }

                        </>
                      );
                    })}


                  </div>
                </>


              )


            })}

          </>
        )





      })}
      <div className="fd-total-time">
        <div>{t("Total Flight Time")} <span><i className="far fa-clock"></i>{timeConvert(flightTime)}</span></div>
        <div>{t("Total Trip Duration")} <span><i className="far fa-clock"></i>{timeConvert(flightTime)}</span></div>
      </div>

    </>
  );
}

export default FlightDetailsTabMultiCity;
