import { Dialog, Popover, TextField } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEmail } from "../../appStore/thunks/getEmail";
import Select from "react-select"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PassengerType,bookingStatusDesc,CONFIG } from '../../utils/constant';
import ToastMessages from "../../utils/utility/ToastedMessage";
import {msg} from "../../utils/utility/MessagesContainer"
import InfoMesages from "../../utils/utility/informationMessage";
import { useTranslation } from "react-i18next";
import { env } from "../../appConfig";
import { shortFormFareType } from "../../utils/utility/convertFareTypeShortForm";
import { contractType } from "../../utils/constant";
import i18n from "../../LangConfig";
import { frenchMessages } from "../../utils/utility/FR-DA-MSG";
import DatePicker from "react-datepicker";
import calenderIcon from "../../Assets/calender.svg";
import { updatePnr } from "../../appStore/thunks/updatePnr";
import { bottom } from "@popperjs/core";
import { getPnrDetails } from "../../appStore/thunks/getPnrDetails";
import GetCalenderLanguage from "../../appStore/thunks/getCalenderLanguage";
import DufflePopUp from "../common/DufflePopUp";
import { generateInvoice } from "../../appStore/thunks/generateInvoice";
toast.configure()

function ConfirmationHead(props) {

  const { t } = useTranslation();
  const locals = GetCalenderLanguage()
  const dispatch = useDispatch();
  const [emailTo,setEmailTo]=useState({label: 'Email to travel agent', value: 'agent'}) 
  const [optionDatePopup, setOptionDatePopup] = useState(false)
  const [ticketingStatusMsg, setTicketingStatusMsg] = useState(false)
  const [date,setDate] = useState(null)
  const [optionDateValidation,setOptionDateValidation] = useState(false)
  const [showInvoiceButton, setInvoiceButton] = useState(true);
  const lang = useSelector( (state) => state.lang.lang)
  const isInternalUserAutheticated = useSelector((state) => state.internalUser.isInternalUserAutheticated);
  const flightDetail = useSelector((state) => state.flightBooking);
  const bookingData = useSelector((state) => state.flightBooking.bookingDetails);
  const bookingStatus = useSelector((state) => state.flightBooking.bookingStatus);
  const searchForm = useSelector((state) => state.searchForm);
  const agentProfile = useSelector((state) => state.agentProfile);
  let provider = useSelector((state) => state.flightBooking.providerName);
  const [configuration,] = useState(CONFIG[provider])
  const {  providerId} = searchForm  
  const { orgId, agencyId, agencyGroupId} = agentProfile
  let optionDateToDisplay = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.optionDateToDisplay
  let supplierLastTickatableDate = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.supplierLastTicketableDate;
  const emailLanguageWhileBooking = useSelector((state) => state.flightBooking.emailLanguage);
  const totalSellingPrice = useSelector(
    (state) => state.flightBooking.totalSellingPrice
  );
  const flightBookingDate = useSelector((state) => state.flightBooking.bookingDate);
  const recordLocator = flightDetail && flightDetail.pnr;

  const seat = useSelector((state) => state.itinenaries.seat);
  const allData = useSelector((state) => (state.flightBooking.getPnrResult));
  let totalSeatAmount = 0
  seat && seat.map(obj => {
    let amount = obj.amount
    totalSeatAmount = Number(totalSeatAmount) + Number(amount)

  })
  const agencyDetails = useSelector((state) => state.agentProfile.agencyDetails);
  const paymentTypeDetails = bookingData && bookingData.payments;
  const freeTextFareRules1 = useSelector((state) => state.Penalaties.freeTextFareRules);
  const faresPerTravellers = bookingData?.itineraryFare?.paxFares
  const passangers = bookingData && bookingData.passengers;
  const segments = bookingData && bookingData.segments;
  const paxTypeDetails = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.paxFares
  let fareTypeStatus = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.type
  let totalAmount = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.totalAmount && bookingData.itineraryFare.totalAmount.amount
  let fareBasisDetails = paxTypeDetails && paxTypeDetails[0] && paxTypeDetails[0].fareBasisDetails
  let primaryEmailId = passangers && passangers[0] && passangers[0].contact && passangers[0].contact.emails && passangers[0].contact.emails[0] && passangers[0].contact.emails[0].email
  const paymentRules = useSelector((state) => state.flightBooking.paymentRules);
  let agencyMarkups = paymentRules && paymentRules.agencyMarkups
  let contractTypes = paymentRules && paymentRules.itineraryFare && paymentRules.itineraryFare.contractType

  let PassengerWiseTransactionsDetails = paymentRules && paymentRules.PassengerWiseTransactionsDetails
  const merchantFeeDetail = bookingData && bookingData.merchantFeeAppliedOnTransaction
  let carrier = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.platingCarrierDetails && bookingData.itineraryFare.platingCarrierDetails.carrier
  let totalSellingAmount = bookingData && bookingData.itineraryFare && bookingData.itineraryFare?.totalAmount?.amount;
  const carrierDataForEmail=bookingData && bookingData.itineraryFare && bookingData.itineraryFare.platingCarrierDetails

  const markup = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.applicableContract && bookingData.itineraryFare.applicableContract.markup
  let netAdultMarkup = markup && markup.amountPerAdult && markup.amountPerAdult.amount ? markup.amountPerAdult.amount : 0
  let netChildMarkup = markup && markup.amountPerChild && markup.amountPerChild.amount ? markup.amountPerChild.amount : 0
  let netInfantMarkup = markup && markup.amountPerInfantWithSeat && markup.amountPerInfantWithSeat.amount ? markup.amountPerInfantWithSeat.amount : 0
  const otherRateComponents = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.otherRateComponents;

  const pnrBookingDetails = useSelector(
    (state) => state.flightBooking.pnrCancellationStatus
  );
  const flightType = useSelector((state)=>state.searchForm.flightType)
  const isPassportMandatory = useSelector((state) => state.itinenaries.isPassport);  
  let isDuffleFlow = useSelector((state)=> state.itinenaries.providerType);
  const [emailPopUpDisplay, setEmailPopUpDisplay] = useState(false)
  const [emailDetails, setEmailDetails] = useState("")
  const [emailDetails1, setEmailDetails1] = useState("")
  const [emailLanguage, setEmailLanguage] = useState("")
  const [addField, setAddField] = useState(false)
  const [btnloader, setBtnloader] = useState(false);
  const [isLoader, setLoder] = useState(false)
  const [openPopUp, setOpenPopUp] = useState(false);
  const [emailOption, setEmailOption] = useState({value:"Please Select",label:t("Please Select")});
  const [selectedEmailOption, setSelectedEmailOption] = useState("");
  const [selectedEmailRecieverOption, setSelectedEmailRecieverOption] = useState("");
  const [buttonloader, setButtonloader] = useState(false);


  let paxTypeAdult = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.paxFares.filter(data => data.passengerDetails.type === PassengerType.ADULT)
  let paxTypeChild = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.paxFares.filter(data => data.passengerDetails.type === PassengerType.CHILD)
  let paxTypeInfant = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.paxFares.filter(data => data.passengerDetails.type === PassengerType.INFANTINLAP)


  let adultsCount = paxTypeAdult && paxTypeAdult[0] && paxTypeAdult[0].passengerDetails && paxTypeAdult[0].passengerDetails.quantity ? paxTypeAdult[0].passengerDetails.quantity : 0
  let childCount = paxTypeChild && paxTypeChild[0] && paxTypeChild[0].passengerDetails && paxTypeChild[0].passengerDetails.quantity ? paxTypeChild[0].passengerDetails.quantity : 0
  let infantCount = paxTypeInfant && paxTypeInfant[0] && paxTypeInfant[0].passengerDetails && paxTypeInfant[0].passengerDetails.quantity ? paxTypeInfant[0].passengerDetails.quantity : 0

  let adultMarkupValue = 0
  let childMarkupValue = 0
  let infantMarkupValue = 0
  let ticketingFee = 0;
  let bookingFee = 0;
  let cancelledFlightCode = ["HX","UC","UN","NO","WK"];

  // logic for language selection
  useEffect(() =>{i18n.changeLanguage(lang)},[lang])

  useEffect(()=>{
    let emailLang = emailLanguageWhileBooking
    setEmailLanguage({value:emailLang,label:t(emailLang)})
    if(env.isProd === true){
      setEmailDetails(pnrBookingDetails?.travelAgent?.email)
    }
    
    if(emailOption && emailOption?.label){
      let val=emailOption;
      let newVal=t(val.value)
      val.label=newVal
      setEmailOption(val)
    }
  },[emailLanguageWhileBooking,lang])

 

  if (agencyMarkups && agencyMarkups.length > 0) {

    let adultsData = agencyMarkups.filter(obj => obj.paxType === PassengerType.ADULT)
    let infantData = agencyMarkups.filter(obj => obj.paxType === PassengerType.INFANTINLAP)
    let childData = agencyMarkups.filter(obj => obj.paxType === PassengerType.CHILD)

    if (adultsData && adultsData[0]) {
      adultMarkupValue = adultsData[0].perPaxAmount && adultsData[0].perPaxAmount.amount
    }
    if (childData && childData[0]) {
      childMarkupValue = childData[0].perPaxAmount && childData[0].perPaxAmount.amount
    }
    if (infantData && infantData[0]) {
      infantMarkupValue = infantData[0].perPaxAmount && infantData[0].perPaxAmount.amount
    }

  }

  let newAdultMarkup = (Number(netAdultMarkup) * Number(adultsCount)).toFixed(2)
  let newChildMarkup = (Number(netChildMarkup) * Number(childCount)).toFixed(2)
  let newInfantMarkup = (Number(netInfantMarkup) * Number(infantCount)).toFixed(2)
  console.log("newAdultMarkup",newAdultMarkup ,netAdultMarkup ,adultsCount)

  const handleClose = () => {

    setEmailPopUpDisplay(false)
    setTicketingStatusMsg(false)
  };
  const handleEmailBtnClick = () => {
    setEmailPopUpDisplay(true)
  }

  const timeConvertIntoHour = (d) => {
    d = Number(d);
    var hour = Math.floor(d / 60);
    return hour
  }

  const timeConvertIntoMin = (d) => {
    d = Number(d);
    // var h = Math.floor(d / 3600);
    var min = Math.floor(d % 60);
    return min
  }

  function timeConvert(n) {
    let number = n;
    let hours = number / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    return (number = rhours + "h " + rminutes + "m");
  }

  function dateToBeFormatted(dateToBeFormatted) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const frenchMonths =[
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre"
    ]
    const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
    const dayNamesInFrench = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
    const year = dateToBeFormatted.slice(0, 4);
    const day = dateToBeFormatted.slice(8, 10);
    const d = new Date(dateToBeFormatted);
    const month = emailLanguage?.value === t("French") ? frenchMonths[d.getMonth()] : monthNames[d.getMonth()];
    const dayName = emailLanguage?.value === t("French") ? dayNamesInFrench[d.getDay()] : dayNames[d.getDay()];
    //time
    const finalDate = `${dayName}, ${day} ${month} ${year}`;
    return finalDate;
  };


  let addOnsDetails = []

  // SPECIAL REQUEST ADDED.


  passangers && passangers.map(data => {

    let addOnArrayNew = []
    let userFirstName = data.firstName?.toUpperCase();
    let userLastName = data.lastName?.toUpperCase();
    let addOnsData = data.addOns


    addOnsData && addOnsData.map(value => {

      addOnArrayNew.push({
        flightNumber: value.flightNumber,
        seatNumber: value && value.seat && value?.seat?.seatNumber,
        wheelChair: value.wheelChair,
        assistance: value.assistance,
        meal: value.meal,
        isSeatConfirmed:value?.seat?.status === "Confirmed" ? 'https://s3-ca-central-1.amazonaws.com/frontend-travelbrands-logos/green-checkmark-icon.svg' : 'https://s3-ca-central-1.amazonaws.com/frontend-travelbrands-logos/close-red-icon.svg'

      })






    })



    addOnsDetails.push({
      firstName: userFirstName?.toUpperCase(),
      lastName: userLastName?.toUpperCase(),
      specialRequest: addOnArrayNew

    })



  })


const [emailVali,setEmailVali] = useState(false)
  const handleSendClick = async () => {

    if(emailDetails == ''){
      setEmailVali(true)
      return
    }
    setBtnloader(true);
    let firstPassgener = passangers[0]?.prefix?.toUpperCase() + " " + passangers[0]?.firstName?.toUpperCase() + " " + passangers[0]?.lastName?.toUpperCase() 
    let isPaymentDoneFlag = flightDetail?.bookingStatus?.code == "PT" ? true : false;
    let sub 
    if(emailLanguage?.value === t("English") && emailTo?.value === 'agent'){
      if(isPaymentDoneFlag){
        sub =  `INTAIR Electronic Ticket  ${recordLocator} ${firstPassgener}`
      } else {
        sub =  `INTAIR Agency Booking & Price Confirmation  ${recordLocator} ${firstPassgener}`
      }
    } else if(emailLanguage?.value === t("English") && emailTo?.value === 'passanger'){
      if(isPaymentDoneFlag){
        sub =  `INTAIR Electronic Ticket  ${recordLocator} ${firstPassgener}`
      } else {
        sub =  `INTAIR Passenger Booking & Price Confirmation  ${recordLocator} ${firstPassgener}`
      }

    } else if(emailLanguage?.value !== t("English") && emailTo?.value === 'agent'){
      if(isPaymentDoneFlag){
        sub =  `Billet électronique INTAIR  ${recordLocator} ${firstPassgener}`
      } else {
        sub =  `Réservation d'agence INTAIR et confirmation de prix  ${recordLocator} ${firstPassgener}`
      }
    } else if(emailLanguage?.value !== t("English") && emailTo?.value === 'passanger'){
      if(isPaymentDoneFlag){
        sub =  `Billet électronique INTAIR  ${recordLocator} ${firstPassgener}`
      } else {
        sub =  `Réservation de passagers INTAIR et confirmation de prix  ${recordLocator} ${firstPassgener}`
      }
    }

    let fileSubName = "Booking.pdf"
    if(emailLanguage?.value !== t("English")){
      fileSubName = "Réservation.pdf"
    }

    let filename=""
    if(flightDetail?.bookingStatus?.code == "PT"){
      if(emailLanguage?.value === t("English")){
        filename="Electronic Ticket.pdf"
      }
      else{
        filename="Billet Électronique.pdf"
      }
    }else{
      filename= fileSubName
    }

    const emailBody = {

      From: "DoNotReply@travelbrands.com",
      To: [

        emailDetails,
        emailDetails1
      ],
      Subject:sub,
      recordLocator: recordLocator,
      language: emailLanguage?.value,
      emailType: selectedEmailOption, //"BOOKINGEMAIL",//
      receiverType: selectedEmailRecieverOption, //"TravelAgent",//
      EmailAttachmentTemplates: [
        {
            "fileName": filename,
            "type": "application/pdf"
        }
    ]

    }


    let result = await dispatch(getEmail(emailBody))

    if (result.status == 0) {
      setBtnloader(false);
      setEmailPopUpDisplay(false)
      InfoMesages(t('502'))

    } 
    else if (result?.data?.message == "eticket number is not present in the PNR") {
      setBtnloader(false);
      setEmailPopUpDisplay(false)
      ToastMessages(t('1018'));
    } else {
      setBtnloader(false);
      setEmailPopUpDisplay(false)
      ToastMessages(t('117'));
    }



  }
  const handleCancelClick = ()=>{
    setEmailPopUpDisplay(false)
    setEmailTo({
      label: "Email to travel agent",
      value: "agent"
    })
      setIsEmailCheck(true)
      if(env.isProd === true){
        setEmailDetails(pnrBookingDetails?.travelAgent.email)
      } else {
        setEmailDetails('')
      }
  }

  const handleEmailChange = (value) => {
    setEmailVali(false)
    setEmailDetails(value)
  }

  const handleEmailChange1 = (value) => {
    setEmailDetails1(value)
  }

  const hanldeLanguageChange = (value) => {

    setEmailLanguage({value:value.value,label:t(value.label)})
  }

  const handleAddFields = () => {
    setAddField(true)
  }

  const handleRemoveFields = () => {
    setAddField(false)
  }

  const [isEmailCheck ,setIsEmailCheck]=useState(true)
  const setEmailFunction = (value) =>{
   
    let age = {
      label: "Email to travel agent",
      value: "agent"
    } ;
    let pass =  {
      label: "Email to travel passanger",
      value: "passanger"
    }

    if(value == "passanger"){
      setIsEmailCheck(false)
      setEmailTo(pass)
      setEmailDetails(primaryEmailId !== undefined ? primaryEmailId : "")
    }else{
      setEmailTo(age)
      setIsEmailCheck(true)
      setEmailDetails(pnrBookingDetails?.travelAgent.email);
    }
  }

  const handleChangeDate = () => {

  setOptionDatePopup(true)
  setDate(null)

  }

  const handleChange = (date) => {
  
    let hour = 23;
    let min = 59;
    var d1 = moment(date).format("YYYY-MM-DD")
    var d2 = moment(supplierLastTickatableDate).format("YYYY-MM-DD")
  
    let supplierTicketableDateHours = moment(supplierLastTickatableDate).hours()
    let supplierTicketableDateMin = moment(supplierLastTickatableDate).minutes()

    if(d1 === d2){
      hour = supplierTicketableDateHours - 1;
      min = supplierTicketableDateMin;
      setOptionDateValidation(false)
    }else if(d1 > d2){
      setOptionDateValidation(true)
    }else{
      setOptionDateValidation(false)
    }

    if(date === null){
      setDate(null)
    }else{
    const updatedDate = moment(date).set({"hour": hour, "minute": min});
    setDate(moment(updatedDate).format("YYYY-MM-DD[T]HH:mm:ss"));
    }
  }

  const handleSaveOptionDate = async() => {
    if(date === null) return 
    setLoder(true)
    let payload={
      providerId: providerId,
      organizationId: orgId,
      agencyId: agencyId,
      agencyGroupId: agencyGroupId,
      recordLocator: flightDetail.pnr,
      optionDate:date
    }
    let getBookingPayload = {
      providerId: providerId,
      organizationId: orgId,
      agencyId: agencyId,
      agencyGroupId: agencyGroupId,
      recordLocator: flightDetail.pnr,
    }

    let result = await dispatch(updatePnr(payload));
    if(result.optionDateStatus === "Success"){
    let getBookingResult = await dispatch(getPnrDetails(getBookingPayload))
    toast.success(t("1015"), { position: toast.POSITION.TOP_CENTER, autoClose:true, progressStyle:{"transform-origin": "right"}}); 
    }
    if(result.optionDateStatus === "Failed"){
      toast.error(t("1014"), { position: toast.POSITION.TOP_CENTER, autoClose:true, progressStyle:{"transform-origin": "right"}}); 
    }
    setLoder(false)
    setOptionDatePopup(false)
    console.log("result1234",result)
  }

  const handleCancelOption = () => {
    setOptionDatePopup(false)
    setOptionDateValidation(false)
  }
  let ticketStatus = flightDetail &&  flightDetail.bookingDetails && flightDetail?.bookingDetails?.ticketingDetails && flightDetail?.bookingDetails?.ticketingDetails?.[0]?.ticketStatus;
  let cancellationStatus = flightDetail?.pnrCancellationStatus?.cancellationStatus;
  const hanldeTicketingStatusVer = () => {
     setTicketingStatusMsg(true)
  }

  const openPop = () => {
    setOpenPopUp(true)
  }

  const closePopUp = (id) => {
    setOpenPopUp(false)
  }

  const handleEmailOption = (value) => {
    setEmailOption({value:value.value,label:t(value.value)})
    if(value.value == "Agency: Net Fare and Itinerary" || value.value == "Agency: Electronic ticket"){
      setEmailFunction("agent")
    }
    if(value.value == "Passenger: Itinerary only" || value.value == "Passenger: Electronic ticket"){
      setEmailFunction("passanger")
    }
    setSelectedEmailOption(value.value)
    switch(value.value){
      case "Agency: Net Fare and Itinerary":
        setEmailFunction("agent")
        setSelectedEmailRecieverOption("TRAVELAGENT")
        setSelectedEmailOption("BOOKINGEMAIL")
        handleEmailBtnClick()
      break;

      case "Agency: Electronic ticket":
        setEmailFunction("agent")
        setSelectedEmailRecieverOption("TRAVELAGENT")
        setSelectedEmailOption("TICKETINGEMAIL")
        handleEmailBtnClick()
      break;

      case "Passenger: Itinerary only":
        setEmailFunction("passanger")
        setSelectedEmailRecieverOption("TRAVELLER")
        setSelectedEmailOption("BOOKINGEMAIL")
        handleEmailBtnClick()
      break;
      
      case "Passenger: Electronic ticket":
        setEmailFunction("passanger")
        setSelectedEmailRecieverOption("TRAVELLER")
        setSelectedEmailOption("TICKETINGEMAIL")
        handleEmailBtnClick()
      break;
    }
    
  }

  const generateInvoiceFunc=async()=>{
    setButtonloader(true);
    let payload = {
      organizationId: orgId,
      agencyId: agencyId,
      recordLocator: flightDetail.pnr,
    }
    let res=await dispatch(generateInvoice(payload))
    if (res && res.hasOwnProperty("success") && res.success) {
      setButtonloader(false);
      setInvoiceButton(false);
      InfoMesages(t("Successfully generated the invoice on Travcom."))
    } else if (res && res.hasOwnProperty("message")) {
      setButtonloader(false);
      ToastMessages(t("Failed to generate the invoice on Travcom, please try again."))
    }else{
      setButtonloader(false);
      ToastMessages(t("Failed to generate the invoice on Travcom, please try again."))
    }

  }

  return (
    <>
      {flightDetail && flightDetail.bookingStatus && (flightDetail.bookingStatus.code !== "NP" && flightDetail.bookingStatus.code !== "SC" && flightDetail.bookingStatus.code !== "CU") ? (
        <div className="bookingconfirmed">
          <div className="subsecondhead">
            {configuration.showAirlineRecordLocator ?
              <div className="subsecondheaddiv">
                <span>{t("Record Locator")}</span>
                <span className="flightPNR">
                  {allData?.airlineRecordLocator}&nbsp;
                  <span className={flightDetail.bookingItenaries && configuration.showAirlineRecordLocator ? "web_class" : ""}>
                    ({flightDetail.bookingItenaries && configuration.showAirlineRecordLocator ?
                      <span className={"web_class"} onClick={openPop}>WEB</span>
                      :
                      flightDetail.bookingItenaries?.providerName})</span>
                  {configuration.showAirlineRecordLocator &&
                    <span>- {flightDetail.pnr}  ({flightDetail.bookingItenaries?.providerName})</span>
                  }
                </span>
              </div>
              :
              <div className="subsecondheaddiv">
                <span>{t("Record Locator")}</span>
                <span className="flightPNR">
                  {flightDetail.pnr}
                  <span className={flightDetail.bookingItenaries && flightDetail.bookingItenaries?.providerName == "NDC" ? "web_class" : ""}>(
                    {flightDetail.bookingItenaries &&
                      flightDetail.bookingItenaries?.providerName == "NDC" ?
                      <span className={"web_class"} onClick={openPop}> WEB</span>
                      :
                      flightDetail.bookingItenaries?.providerName}
                    )</span>
                </span>
              </div>
            }

            {isInternalUserAutheticated && allData && allData.isPaymentAddedtoProvider==false && !isDuffleFlow &&
              <div className="subsecondheaddiv">
                <span className="tktonholdFailed">
                <i className="fas fa-times-circle"></i> Payment failed in PNR
                </span>
              </div>
            }

            {flightDetail && flightDetail.bookingStatus && (flightDetail.bookingStatus.code=== "NTC" || flightDetail.bookingStatus.code=== "TC" || flightDetail.bookingStatus.code=== "PNTC" || flightDetail.bookingStatus.code=== "CR") ?
              <div className="subsecondheaddiv bookingCancel">
                <span>{t("Booking Status")}</span>
                <span className="tktonholdFailed">
                  <i className="fas fa-times-circle"></i> {flightDetail && flightDetail.bookingStatus && t(flightDetail.bookingStatus.description)}
                </span>
              </div>
              :
              <div className="subsecondheaddiv">
                <span>{t("Booking Status")}</span>
                <span className="tktonhold">
                  <i className="fas fa-check-circle"></i> {t(flightDetail?.bookingStatus?.description)}
                </span>
              </div>
            }
            {/* {allData?.ticketStatus !== "Empty" &&
              <div className="subsecondheaddiv">
              <span>{t("Ticketing Status:")}</span>
              
              {
               <span className={"flightPNR"}>{t(allData?.ticketStatus)}</span>
              }      
               {(ticketStatus === "Voided") &&                
               <span className="ticketing-status-ver">
                <i className="fas fa-info-circle" onClick={hanldeTicketingStatusVer}></i>              
               </span>
               }                                 
            </div>} */}
          </div>
          {!(bookingStatus?.code == "NTC" ||bookingStatus?.code == "TC" ||bookingStatus?.code == "PNTC") &&
            <div className="subfirsthead justify-content-end">
              {!(agencyId == undefined || agencyId == null || agencyId == "") && agencyDetails?.enableTravcomInvoices && bookingStatus?.code == "PT" && (!allData?.isInvoiceGenerated) && showInvoiceButton &&
                <div className="pInfo-submit-btn mb-0 mr-3 postion-r">
                  <button className={buttonloader ? "loading blueButton" : "blueButton"} onClick={() => generateInvoiceFunc()}>{t("Generate Invoice")}</button>
                </div>
              }
           
           <div className="emailOptionSelectMainContainer">
                <p className="emailbook" style={{ "paddingTop": "0.4rem" }}>
                  <i className="fas fa-envelope-square"></i>{" "}
                </p>
                <div style={{ "width": "95%" }}>
                  <Select
                    options={[
                      {
                        label: t("Passenger: Electronic ticket"),
                        value: "Passenger: Electronic ticket"
                      },
                      {
                        label: t("Passenger: Itinerary only"),
                        value: "Passenger: Itinerary only"
                      },
                      {
                        label: t("Agency: Electronic ticket"),
                        value: "Agency: Electronic ticket"
                      },
                      {
                        label: t("Agency: Net Fare and Itinerary"),
                        value: "Agency: Net Fare and Itinerary"
                      }
                    ]}
                    placeholder={t("Select Language")}
                    onChange={(e) => handleEmailOption(e)}
                    value={emailOption}
                  />
                </div>
              </div>
            </div>}
          

        </div>
      ) : (
        <div className="bookingconfirmed bookingonhold">

          <div className="subsecondhead">
            {configuration.showAirlineRecordLocator ?
              <div className="subsecondheaddiv">
                <span>{t("Record Locator")}</span>
                <span className="flightPNR">
                  {allData?.airlineRecordLocator}&nbsp;
                  <span className={flightDetail.bookingItenaries && configuration.showAirlineRecordLocator ? "web_class" : ""}>
                    ({flightDetail.bookingItenaries && configuration.showAirlineRecordLocator ?
                      <span className={"web_class"} onClick={openPop}>WEB</span>
                      :
                      flightDetail.bookingItenaries?.providerName})</span>
                  {configuration.showAirlineRecordLocator &&
                    <span>- {flightDetail.pnr}  ({flightDetail.bookingItenaries?.providerName})</span>
                  }
                </span>
              </div>
              :
              <div className="subsecondheaddiv">
                <span>{t("Record Locator")}</span>
                <span className="flightPNR">
                  {flightDetail.pnr}
                  <span className={flightDetail.bookingItenaries && flightDetail.bookingItenaries?.providerName == "NDC" ? "web_class" : ""}>(
                    {flightDetail.bookingItenaries &&
                      flightDetail.bookingItenaries?.providerName == "NDC" ?
                      <span className={"web_class"} onClick={openPop}> WEB</span>
                      :
                      flightDetail.bookingItenaries?.providerName}
                    )</span>
                </span>
              </div>
            }
            <div className="subsecondheaddiv">
              <span>{t("Booking Status")}</span>
              <span className="tktonhold">
                <i className="fas fa-pause-circle"></i> {flightDetail && flightDetail.bookingStatus && t(flightDetail.bookingStatus.description)}
                
              </span>
            </div>
            {/* <div className="subsecondheaddiv">
              {(ticketStatus === "Voided") &&
              <span className="ticketing-status-ver">
                <i className="fas fa-info-circle" onClick={hanldeTicketingStatusVer}></i>              
              </span>
              }
            </div> */}
          </div>
          <div className="subfirsthead">
           
            <div className="d-flex">

              <p className="bookheadp">{t("Your Booking is")} {flightDetail && flightDetail.bookingStatus && t(flightDetail.bookingStatus.description)}</p>
             
              <span className="bookDate"> 
                                {moment.utc(optionDateToDisplay).format(
                                  "DD MMM  YYYY" 
                                )}                                
                                {" "}|{" "} 
                                {moment.utc(optionDateToDisplay).format(
                                  "HH:mm" 
                                )} ET
                                </span>
            {(props.from === "confirmation" && props.isInternalUserAutheticated && !isPassportMandatory && supplierLastTickatableDate) && configuration.allowDateChange &&
            <p className="emailbook" style={{paddingLeft:"0.813rem",fontSize:"1.1rem"}}>
              <span onClick={handleChangeDate}>{t("Change Date")}</span>
            </p> 
            }                
            </div>
              <div className="emailOptionSelectMainContainer">
                <p className="emailbook" style={{ "paddingTop": "0.4rem" }}>
                  <i className="fas fa-envelope-square"></i>{" "}
                </p>
                <div style={{ "width": "95%" }}>
                  <Select
                    options={[
                      {
                        label: t("Passenger: Electronic ticket"),
                        value: "Passenger: Electronic ticket"
                      },
                      {
                        label: t("Passenger: Itinerary only"),
                        value: "Passenger: Itinerary only"
                      },
                      {
                        label: t("Agency: Electronic ticket"),
                        value: "Agency: Electronic ticket"
                      },
                      {
                        label: t("Agency: Net Fare and Itinerary"),
                        value: "Agency: Net Fare and Itinerary"
                      }
                    ]}
                    placeholder={t("Select Language")}
                    onChange={(e) => handleEmailOption(e)}
                    value={emailOption}
                  />
                </div>
              </div>

          </div>
          {optionDatePopup &&
             <div>
              <div className={lang === "fr" ? "Option-Date-Container-fr" : "Option-Date-Container"}>
              <span style={{fontSize:"18px", fontWeight:"600"}}>{t("Please Enter Date")} </span>
             <div className={lang !=="fr" ? "set-DatePickerM optionDate_Picker" : "set-DatePickerM optionDate_Picker-fr"}>
             
            <DatePicker
                enableTabLoop={false}
                // ref={dateRef}
                // id={id}
                className="form-control set_DatePicker_h "
                selected={Date.parse(date)}
                onChange={date => handleChange(date)}
                // onClick={getId(props.id)}
                minDate={Date.parse(flightBookingDate)}
                // maxDate={Date.parse(supplierLastTickatableDate)}
                // onKeyDown={dateChangeOnTab}
                // startDate={Date.parse(startDate)}
                // endDate={Date.parse(endDate)}
				        // selectsStart={selectsStart}
				        // selectsEnd={selectsEnd}
                
                placeholderText={t("MM/DD/YYYY")}
                dateFormat="MM/dd/yyyy"
                locale={locals}
                monthsShown={1}
                popperPlacement="top-end"
                autoComplete='off'
            />
           
            <span className="pt-1 pr-1" style={{
                alignItems: "center",
                display: "inline-flex",
                justifyContent: "center",
                pointerEvents: "none",
                position: "absolute",
                left:"355px",
                bottom:"70px",
                zIndex: 4
            }}>
               <img className="img-fluid ser_CalenderIcon" src={calenderIcon} />
            </span>
             </div>                         
              <span className={(date === null || date === undefined || optionDateValidation) ? "ser-card_sub bd-button-calculator-disabled" : "ser-card_sub bd-button-calculator"}>
                <button className={(date === null || date === undefined || optionDateValidation)  ? "Option-Date-Cta-Disabled" : isLoader ? "Option-Date-Cta loading" : "Option-Date-Cta"}
                    onClick={handleSaveOptionDate}
                    aria-controls="calc_rules_tab"
                    disabled={(date === null || date === undefined || optionDateValidation)}
                    >{t("Save")}
                </button>
               </span>
              <span className={isLoader ? "ser-card_sub bd-button-calculator-disabled" : "ser-card_sub bd-button-calculator"}>
                <button 
                className={isLoader ? "Option-Date-Cta-Disabled":"Option-Date-Cta"}
                onClick={handleCancelOption}
                aria-controls="calc_rules_tab"
                disabled={isLoader}
                >{t("Cancel")}
                </button>              
              </span>            
             
              </div>
             </div>  
            }
            {optionDateValidation &&
           
           <div className='payment-error-msg-wrapper' style={{position: "absolute",
             bottom: "3px"}}>
           <div className="seat-udate-status">
             <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
               <span>{t("New option date exceeds maximum permitted date")}</span>
           </div>
            </div>
            }
            
        </div>
      )}      
      <Dialog
        open={emailPopUpDisplay}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className="cancelBook-dialog"
      >
        <div className="cancelBook-wrapper onOption">
          <div className="cancelBook-box">
                <div className="cancelBook-input" style={{marginTop:'5px'}}>
              <div className="cancelBook-input-col">
                <div className="bd-pi-input add-email">
                  <TextField
                    id="travelagentname"
                    name="travelagentname"
                    label={t("Email Address")}
                    onChange={(e) => { handleEmailChange(e.target.value) }}
                    variant="outlined"
                    value={emailDetails}
                  />
                  {emailVali &&
                    <span className="text-danger">{t("Please enter email")}</span>
                  }

                  <button
                    className="ser_AddCityBtn add-email-btn addFields"
                    id="addFields"
                    onClick={() => handleAddFields()}
                  >
                    <i className="fas fa-plus" aria-hidden="true"></i>
                  </button>
                </div>


                {addField &&
                  <div className="bd-pi-input add-email">
                    <TextField
                      id="travelagentname"
                      name="travelagentname"
                      label={t("Email Address")}
                      onChange={(e) => { handleEmailChange1(e.target.value) }}
                      variant="outlined"
                      value={emailDetails1}
                    />

                    <button
                      className="removeBtninput add-email-btn"
                      id="addFields"
                      onClick={() => handleRemoveFields()}
                    >
                      <i className="fas fa-times-circle" aria-hidden="true"></i>
                    </button>


                  </div>
                }
              </div>

              <div className="cancelBook-input-col">
                <div className="bd-pi-input">

                  <Select
                    options={[{
                      label: t("English")
                      ,
                      value: "English"
                    },
                    {
                      label: t("French"),
                      value: "French"
                    }
                    ]}
                    placeholder={t("Select Language")}
                    onChange={(e) => hanldeLanguageChange(e)}
                    value={emailLanguage}
                  />
                </div>
              </div>

              <div className="cancelBook-input-col cancelBook-cta">
                <button className={btnloader ? "btn with-payment loading" : "btn with-payment"} onClick={handleSendClick}>{t("Send")}</button>
                <button onClick={handleCancelClick}>{t("Cancel")}</button>
              </div>
           
            </div>
          </div>




        </div>


     </Dialog>
      <Dialog
        open={ticketingStatusMsg}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className="cancelBook-dialog"
      >
      <div className="cancelBook-wrapper noRulesFit" style={{width: "561px"}}>
                <div className="cancelBook-box">
                    <div className="cancelBook-box-info">
                        <span className="cm-close" onClick={handleClose}>&times;</span>
                        <i className="fas fa-info-circle"></i> {t("Ticketing Status Verbiage")}</div>
                </div>
            </div>
      </Dialog>
      {openPopUp &&
        <DufflePopUp
          open={openPopUp}
          handleClose={closePopUp}
        />
      }
    </>
  );
}
export default ConfirmationHead;
