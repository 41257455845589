import { useHistory } from "react-router-dom";
import { useDispatch,useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';

import {
  setFlightType,
  setUrlUsd
} from "../../../appStore/actionCreators/searchForm";
import { env } from '../../../appConfig';
import { useEffect } from "react";
import i18n from "../../../LangConfig";
import {useTranslation } from "react-i18next";
import { resetAppStore } from '../../../appStore/resetAppStore';
import getApiResponse from '../../../services/getApiResponse';


function Header(props) {
  // translation Object converts the language
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const lang = useSelector( (state) => state.lang.lang);
  const uuid_val = useSelector((state) => state.searchForm.uuid);
  // const orgId = useSelector((state) => state.agentProfile.orgId);
  // const agentToken = useSelector((state) => state.agentProfile.agentToken);
  const internalUserData = useSelector((state)=> state.internalUser.internalUserData);
  const internalUserAccessToken = useSelector((state)=> state.internalUser.internalUserAccessToken);
  const isInternalUserAutheticated = useSelector((state) => state.internalUser.isInternalUserAutheticated);

  // const handleArrowClick = () => {
  //   history.push(`/`)
  // }

  const logOutFunction = async () => {
    //Axios Payload
    const config = {
      method: "POST",
      url: env.REACT_APP_PROFILE_ORG_BASE_URL + `/auth/users/internal/signOut?token=${internalUserAccessToken}`,
      headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "cache-control": "no-cache",
      }
    }
    const result = await getApiResponse(config);
    console.log(result)
    // if(result){
    //   resetAppStore();
    // }  
    history.push(`/`)
    dispatch({ type: "RESET_EVERYTHING" });
    dispatch(setFlightType("CAD"));
    dispatch({ type: "CHANGE LANGUAGE", data: "en" });
  };
  
//  Handle Lang Change For APP 
useEffect(()=> {
  i18n.changeLanguage(lang)
 },[lang])
const langChange = ({ target }) => {
  let { value } = target;
  dispatch({ type: "CHANGE LANGUAGE", data: value });
  i18n.changeLanguage(value);
}

  return (
    <>
      <header className='main_header'>
        <div className={props.setClass}>
          <div className="row">
           
              {/* <i className={'far fa-arrow-alt-circle-left ' + props.show} onClick={handleArrowClick}></i> */}
            {/* <div className="col-5 p-0 ">
              <nav className="navbar navbar-expand-sm settingNavbar ">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link" >SAVED ITINENARY</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" >MY BOOKINGS</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link">GROUPS </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" > <i className="fas fa-cog"></i></a>
                  </li>
                          
                </ul>
              </nav>
            </div> */}
          </div>
          <div className='row'>
            <div className='col-12 p-0'>

              <nav className="navbar navbar-expand-md mainNavBar" style={{height: "60px"}}>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                  <span className="navbar-toggler-icon"></span>
                </button>

                {/* <!-- Navbar links --> */}
                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                  <ul className="navbar-nav nav-link col-12">
                  <div className="col-md-7">
                  {/* <img src={logo} className='logo_header' alt='TravelBrands_logo' /> */}
                </div>
                  <p className='float-right'>{t("id :")}  <span>{uuid_val}</span></p>
                    <li className="nav-item ">
                      {/* <a className="nav-link" >TravelGenie</a> */}
                    </li>
                    <li className="nav-item">
                      {/* <a className="nav-link" >Sunquest</a> */}
                    </li>
                    <li className="nav-item">
                      {/* <a className="nav-link" >Flights</a> */}
                    </li>
                    <li className="nav-item">
                      {/* <a className="nav-link" >Hotels</a> */}
                    </li>
                    <li className="nav-item">
                      {/* <a className="nav-link" >Cars</a> */}
                    </li>
                    <li className="nav-item">
                      {/* <a className="nav-link" >Cruises</a> */}
                    </li>
                    <li className="nav-item">
                      {/* <a className="nav-link" >Add-Ons</a> */}
                    </li>
                    <li className="nav-item">
                      {/* <a className="nav-link" >Disney</a> */}
                    </li>
                    <li className="nav-item">
                      {/* <a className="nav-link" >Featured Destinations</a> */}
                    </li>
                    <li className="nav-item">
                      {/* <a className="nav-link" >Specialty Travel/Lujure</a> */}
                    </li>
                    <li>
                    <div style = {{ position:"absolute", right:"90px", top:"17px"}}>
                      <select
                        style = {{padding:" 2px 5px 2px 5px"}}
                        className="Language-Selector"
                        value={lang||"En"}
                        onChange={langChange}
                        >
                        <option value="en">EN</option>
                        <option value="fr">FR</option>
                        </select>
                        </div>
                    </li>
                    
                    {isInternalUserAutheticated &&
                  <li className="nav-item" style={{position: "absolute",
                    right: "19px",
                    top: "12px"}}>
                  <Dropdown >
                    <Dropdown.Toggle className="loginIcon" id="dropdown-basic" style={{height:'40px' ,width:"40px" ,borderRadius:'50%'}}>
                      <span className="" style={{marginLeft:'-6px' ,textTransform:'capitalize'}}>{internalUserData && internalUserData.username?.charAt(0)}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>  {internalUserData && internalUserData.username} </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item onClick={()=> logOutFunction()}>{t("Log out")}</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>                  
                  </li> 
                 }     

                  </ul>
                </div>
              </nav>
            </div>
          </div>

        </div>
      </header>
    </>
  );
}


export default Header;
