import React, { useEffect, useState } from "react";
import AgentInfoIcon from "../../Assets/agentInfo-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { setLandCruiseBookingNumber, setLandCruiseBookingNumberCheck, setValidationOnLandCruise } from "../../appStore/actionCreators/flightBooking";
import { updatePnr } from "../../appStore/thunks/updatePnr";
import { getPnrDetails } from "../../appStore/thunks/getPnrDetails";
import SpinnerLoader from "../common/spinner";
import ToastMessages from "../../utils/utility/ToastedMessage";
import { useTranslation } from "react-i18next";
import i18n from "../../LangConfig";

function BookingNumberForLandCruise(props) {
  const dispatch = useDispatch();
  const lang = useSelector( (state) => state.lang.lang)
  const [isEdit, setIsEdit] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const bookingNumber = useSelector(
    (state) => state.flightBooking.landCruiseBookingNumber
  );
  const bookingDetail = useSelector(
    (state) => state.flightBooking.bookingItenaries
  );
  const searchForm = useSelector((state) => state.searchForm);
  const agentProfile = useSelector((state) => state.agentProfile);
  const flightDetail = useSelector((state) => state.flightBooking);
  const bookingDetails = useSelector((state) => state.flightBooking.pnrCancellationStatus);

  const validation = useSelector((state) => state.flightBooking.validationOnLandCruise);

  
  const { providerId } = searchForm;
  const { orgId, agencyId, agencyGroupId } = agentProfile;

  let from = props.from;
  let fareType =
    bookingDetail &&
    bookingDetail.fareType;

  const handleBookingNumber = (e) => {
    let bookingNumber = e && e.target && e.target.value;
   
    const regex = new RegExp(/^[A-Z0-9]+$/i)
    const isAlphaNumeric = regex.test(bookingNumber);
    if(bookingNumber && bookingNumber.length > 0){
      dispatch(setValidationOnLandCruise(isAlphaNumeric))
    }
    dispatch(setLandCruiseBookingNumber(bookingNumber)); 
    
  };
  // logic for language selection
  useEffect(() =>{i18n.changeLanguage(lang)},[lang])

  useEffect(() => {
    if(bookingNumber === undefined || bookingNumber === ""){
    setIsEdit(true);
    }
  },[])

  const handleBookingNumberEdit = async (editVal) => {
    setIsEdit(!editVal);
    dispatch(setLandCruiseBookingNumberCheck(!editVal))
    if (editVal && from === "confirmation") {
      if(bookingNumber === "" || bookingNumber === undefined) {
        ToastMessages(t('126')) 
        setIsEdit(true);
        dispatch(setLandCruiseBookingNumberCheck(true))
        return false
      }
      if(!validation){
        ToastMessages(t('127'))
        setIsEdit(true);
        dispatch(setLandCruiseBookingNumberCheck(true))
        return false
      }
      setIsLoader(true);
     
      let payload = {
        providerId: providerId,
        organizationId: orgId,
        agencyId: agencyId,
        agencyGroupId: agencyGroupId,
        recordLocator: flightDetail.pnr,
        // pnrOperations: obj,
        nonAirConfirmationNumber: bookingNumber
      };
      let result = await dispatch(updatePnr(payload));
      if (result) {
        let requestPayload = {
          recordLocator: flightDetail.pnr,
          providerId: providerId,
          organizationId: orgId,
          agencyId: agencyId,
          agencyGroupId: agencyGroupId,
        };
        let pnrResult = await dispatch(getPnrDetails(requestPayload));
        if (pnrResult) {
          setIsLoader(false);
        } else {
          setIsLoader(false);
        }
      }
    }
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="bd-travelAgent-info">
        <div className="bd-sab-head">
        {fareType === "Land Net" &&
          <h3>
            <img src={AgentInfoIcon} alt="icon" /> 
            {t("Add Your Travelbrands land component")}
          </h3>
         }
          {fareType === "Cruise Net" &&
          <h3>
            <img src={AgentInfoIcon} alt="icon" /> 
            {t("Add Your Travelbrands cruise")}
          </h3>
         }
          {from === "confirmation" && bookingDetails && bookingDetails.bookingStatusDetail && bookingDetails.bookingStatusDetail.code !== "NTC" && bookingDetails.bookingStatusDetail.code !== "CR" &&
          <>
          {isEdit ? (
            <span style={{padding:"10px"}}>
              <a
                className="bd-sub-head-link"
                onClick={() => handleBookingNumberEdit(isEdit)}
              >
                {t("Save")}
              </a>
            </span>
          ) : (
            <span style={{padding:"10px"}}>
              <a
                className="bd-sub-head-link"
                onClick={() => handleBookingNumberEdit(isEdit)}
              >
                {t("Edit")}
              </a>
            </span>
          )}
          </>
         }
        </div>
        {isLoader ? (
          <div className="col-md-12 text-center mt-3">
            <SpinnerLoader />
          </div>
        ) : (
          <div className="bd-travelAgent-info-details">
            <div>
              <div className="bd-priceSummary">
                <div className="ft-notification">
                  <i className="fas fa-info-circle"></i>
                  {fareType === "Land Net" && (
                    <span>
                     {t("All fees incurred by providing")}
                    </span>
                  )}
                  {fareType === "Cruise Net" && (
                    <span>
                      {t("All fees incurred by providing")}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group bd-pi-formcol pi-formcol-1 bd-booking-number">
              <div
                className={
                  bookingNumber !== undefined && bookingNumber?.length > 0
                    ? "bd-pi-input pi-input-date cust-floting-label active"
                    : "bd-pi-input pi-input-date cust-floting-label"
                }
              >
                <div className="bd-pi-input" style={{ width: lang=="en"?"400px":"445px" }}>
                  <input                   
                    disabled={from === "confirmation" && !isEdit}
                    type="text"
                    className="form-control"
                    aria-label={t("PNR_No")}
                    aria-describedby="addon-wrapping"
                    name="bookingNumber1"
                    id={"bookingNumber1"}
                    autocomplete="off"
                    value={bookingNumber}
                    onChange={(e) => handleBookingNumber(e)}
                  />
                  {fareType === "Land Net" &&
                  <label
                    htmlFor={"bookingNumber1"}
                    className={
                      bookingNumber?.length > 0
                        ? "cust-fl-label"
                        : "cust-fl-label"
                    }
                  >
                  {t('Travelbrands_Land_Component_Booking_Number')}<sup className="asterisk">*</sup>
                  </label>
                  }
                  {fareType === "Cruise Net" &&
                  <label
                    htmlFor={"bookingNumber1"}
                    className={
                      bookingNumber?.length > 0
                        ? "cust-fl-label"
                        : "cust-fl-label"
                    }
                  >
                    {t('Travelbrands_Cruise_Component_Booking_Number')}<sup className="asterisk">*</sup>
                  </label>
                  }
                </div>
                
              </div>
              {!validation &&
                <div className="bd-landCruise-validation">*{t("Special character not allowed")}</div>
                }
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default BookingNumberForLandCruise;
